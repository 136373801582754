<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-icon left>mdi-newspaper-variant</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Album ({{ totalGalleries }})</v-toolbar-title
      >
      <v-spacer></v-spacer>

      <v-btn
        v-if="hasCreatePermission"
        color="primary"
        class="appbar__btn ml-10"
        height="40px"
        :to="{ name: 'gallery.create' }"
      >
        <v-icon class="mr-2">{{ icons.add }}</v-icon>
        Create Album
      </v-btn>
    </v-app-bar>

    <div
      class="mx-4"
      id="gallery-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs v-model="defaultStatus">
          <v-tab
            class="pa-3"
            v-for="item in tabItems"
            :key="item.value"
            :ref="item.value"
            :disabled="defaultStatus !== item.value && loading"
            @click="handleTabClick(item.value)"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title, building or name"
          @input="search"
        ></v-text-field>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="galleryList"
        v-model="selected"
        :loading="loading"
        @update:options="fetchGallery"
        :server-items-length="totalGalleries"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
        :items-per-page.sync="itemsPerPageTables"
        @pagination="handleScrollAfterTableRender"
      >
        <template v-slot:item.is_active="{ item }">
          <div style="display: flex; justify-content: center">
            <v-checkbox v-model="item.is_active" disabled readonly />
          </div>
        </template>
        <template v-slot:item.building="{ item }">
          <div v-if="item.buildingName">{{ item.buildingName }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(galleryList.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Gallery list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown, mdiPlus } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'GalleryPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      active: true,
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
        add: mdiPlus,
      },
      defaultStatus: 'active',
      defaultFilter: 'created_at',
      sortOrder: false,
      tabItems: [
        { tab: 'Active ', value: 'active', index: 1 },
        { tab: 'Inactive', value: 'inactive', index: 0 },
        { tab: 'All', value: 'all', index: 2 },
      ],
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '40%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Active',
          value: 'is_active',
          width: '10%',
          align: 'center',
        },
        {
          text: 'Added Date',
          value: 'created_at',
          width: '15%',
        },
        {
          text: 'Created By',
          value: 'userName',
          width: '25%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      selected: [],
      itemsPerPageTables: 25,
    }
  },

  created() {
    this.fetchInitParams()
  },

  beforeRouteLeave(to, from, next) {
    this.setScrollPosition(window.scrollY)
    next()
  },

  computed: {
    ...mapState({
      galleryList: (state) => state.gallery.list,
      listMeta: (state) => state.gallery.listMeta,
      permissions: (state) => state.auth.permissions,
      totalGalleries: (state) => state.gallery.totalGalleries,
      paramsGallery: (state) => state.gallery.queryParams,
      scrollPosition: (state) => state.gallery.scrollPosition,
    }),

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },

    hasCreatePermission() {
      return validatePermissions(
        [PERMISSION.GALLERIES_CREATE],
        this.permissions
      )
    },
  },

  methods: {
    ...mapActions({
      getGallery: 'gallery/getGallery',
    }),

    ...mapMutations({
      clearGallery: 'gallery/clearGalleryList',
      setParams: 'gallery/setQueryParams',
      setScrollPosition: 'gallery/setScrollPosition',
    }),

    handleTabClick(status) {
      this.itemsPerPageTables = 25
      this.defaultStatus = status
      this.defaultFilter = 'created_at'
      this.fetchGallery()
    },

    fetchInitParams() {
      if (this.paramsGallery?.filter) {
        this.defaultStatus = this.paramsGallery.filter
      }

      if (this.paramsGallery?.sort) {
        if (this.paramsGallery.sort[0].includes('-')) {
          this.defaultFilter = this.paramsGallery.sort.slice(1)
          this.sortOrder = true
        } else {
          this.defaultFilter = this.paramsGallery?.sort
          this.sortOrder = false
        }
      } else {
        this.defaultFilter = 'created_at'
      }

      if (this.paramsGallery?.itemsPerPage) {
        const { itemsPerPage } = this.paramsGallery
        this.itemsPerPageTables = [25, 50, 100].includes(itemsPerPage)
          ? itemsPerPage
          : -1
      }

      if (this.paramsGallery?.search) {
        this.filter.search = this.paramsGallery.search
      }

      this.fetchGallery(null, 1, this.paramsGallery)
    },

    filterList(filterValue) {
      this.fetchGallery(null, filterValue)
    },

    async exportList() {
      this.loading = true

      await this.exportToExcel({
        sort: this.getSortParam(),
      })
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'users_list.xlsx')
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded')
        })
        .catch((err) => {
          this.showSnackbar(
            this.getErrorMessage(err, 'Oops! Something went wrong'),
            false
          )
        })
        .finally(() => {
          this.loading = false
        })
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchGallery(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.fetchGallery()
    }, 600),

    getTabNameByIndex(index) {
      switch (index) {
        case 0:
          return 'active'
        case 1:
          return 'inactive'
        default:
          return 'all'
      }
    },

    async fetchGallery(options, page = 1, paramsGallery = null) {
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalGalleries
            : options.itemsPerPage
          : this.itemsPerPageTables === -1
          ? this.totalGalleries
          : this.itemsPerPageTables,
        filter:
          typeof this.defaultStatus === 'number'
            ? this.getTabNameByIndex(this.defaultStatus)
            : this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : 'title',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearGallery()
      this.loading = true
      await this.getGallery(paramsGallery || params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
          this.scrollToPosition()
        })
      this.setParams(params)
    },
    editItem(item) {
      this.$router.push({
        name: 'gallery.details',
        params: { id: item.id },
      })
    },

    handleScrollAfterTableRender(pagination) {
      if (pagination.pageCount === 0) return
      this.scrollToPosition()
    },

    scrollToPosition() {
      const scrollPosition = this.scrollPosition

      this.$nextTick(() => {
        window.scrollTo({
          top: scrollPosition,
          left: 0,
          behavior: 'smooth',
        })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-input.v-input--checkbox {
  justify-content: center;
  margin: 0;

  .v-input__control {
    width: auto;
    flex-grow: 0;
  }

  .v-input__slot {
    .v-input--selection-controls__input {
      margin-right: 0;
    }
  }
}

.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}

.v-input.v-input--checkbox {
  justify-content: left !important;
}
</style>
